import React, { useRef, useEffect, useCallback } from "react";
import styled from "styled-components";
import { MdClose } from "react-icons/md";
import { useSpring, animated } from "react-spring";

const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
`;

const ModalWrapper = styled.div`
  width: 500px;
  height: 200px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: flex;
  grid-template-columns: 1fr 1fr;
  position: relative;
  z-index: 10;
  border-radius: 10px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  color: #141414;
  text-align: center;
  margin: auto;

  input {
    display: block;
    width: 100%;
    padding: 5px 8px;
    margin: 20px
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid grey;
    text-align: center;
    font-size: 15px;
  }

  button {
    padding: 10px 24px;
    background: #141414;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin: 7px;
    font-size: 15px;
  }
`;

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 100;
`;

export const Modal = ({ state, toggleModal, changeMessage }) => {
  const modalRef = useRef();
  const contentRef = useRef();
  const showModal = state.open;
  const animation = useSpring({
    config: {
      duration: 200,
    },
    opacity: showModal ? 1 : 0,
  });

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      toggleModal();
    }
  };

  const updateMessage = (reset) => () => {
    var value = contentRef.current.value;
    if (reset) {
      value = null;
    }
    changeMessage(state.messageType, value);
    toggleModal();
  };

  const keyPress = useCallback((e) => {
    if (e.key === "Enter" && showModal) {
      updateMessage(false)();
    } else if (e.key === "Escape" && showModal) {
      toggleModal();
    }
  });

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  return (
    <>
      {state.open ? (
        <animated.div style={animation}>
          <Background ref={modalRef} onClick={closeModal}>
            <ModalWrapper showModal={showModal}>
              <ModalContent>
                <input
                  type="text"
                  autofocus
                  ref={contentRef}
                  placeholder={state[state.messageType]}
                  style={{ margin: "10px" }}
                ></input>
                <div>
                  <button onClick={updateMessage(true)}>Reset</button>
                  <button onClick={updateMessage(false)}>Submit</button>
                </div>
              </ModalContent>
              <CloseModalButton
                aria-label="Close modal"
                onClick={toggleModal}
              />
            </ModalWrapper>
          </Background>
        </animated.div>
      ) : null}
    </>
  );
};
