import React, { useState } from "react";

export const FlippableImage = ({ src1, src2, onSelect }) => {
  const [flip, setFlip] = useState(false);
  const src = flip ? src2 : src1;
  return (
    <img
      alt={"plushie"}
      onClick={onSelect}
      onDoubleClick={() => {
        setFlip(!flip);
      }}
      className="plushieImg"
      src={src}
      tabindex="0"
    />
  );
};
