import React, { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Countdown from "react-countdown";
import { AiFillHeart, AiOutlinePlusCircle } from "react-icons/ai";
import { FaCheckSquare, FaTrashAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import ModalVideo from "react-modal-video";
import { CountModal } from "./CountModal";

import "react-datepicker/dist/react-datepicker.css";
import "react-modal-video/scss/modal-video.scss";

class CountDownState {
  constructor(title, date, displayTitle) {
    this.title = title;
    this.date = date;
    this.displayTitle = displayTitle;
  }
}

export const CountDownHandler = () => {
  const [open, setOpen] = useState(false);

  const getAnniversary = () => {
    const currDate = new Date();
    const month = 0;
    const day = 26;
    let year = currDate.getFullYear();
    if (currDate.getMonth() != month || currDate.getDate() > day) {
      year += 1;
    }
    return new Date(year, month, day);
  };

  const surprise = () => {
    setOpen(true);
  };

  const anniversaryDate = getAnniversary();
  const anniversaryRenderer = ({
    formatted: { days, hours, minutes, seconds },
    completed,
  }) => {
    if (completed) {
      // Render a completed state
      return (
        <div className="heartContainer">
          <img className="pulse" src="./heart.png" onClick={surprise} />{" "}
        </div>
      );
    } else {
      // Render a countdown
      return (
        <span>
          <AiFillHeart className="countdownIcon heartIcon" />
          {days}:{hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  const formatDate = (date) => {
    return (
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)) +
      "/" +
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
      "/" +
      date.getFullYear()
    );
  };

  const rendererWrapper =
    (countdown, i) =>
    ({ formatted: { days, hours, minutes, seconds }, completed }) => {
      if (completed) {
        return (
          <span>
            <FaCheckSquare
              onClick={() => removeCountdown(i)}
              className="countdownIcon checkIcon"
            />
            <span>{`${countdown.title}: ${formatDate(countdown.date)}`}</span>
          </span>
        );
      }
      return (
        <span>
          <FaTrashAlt
            onClick={() => removeCountdown(i)}
            className="countdownIcon trashIcon"
          />{" "}
          <span onClick={() => toggleCountdown(i)}>
            {countdown.displayTitle
              ? `${countdown.title}: ${formatDate(countdown.date)}`
              : `${days}:${hours}:${minutes}:${seconds}`}
          </span>
        </span>
      );
    };

  const loadCountdowns = () => {
    return JSON.parse(localStorage.getItem("countdowns") || "[]").map(
      (countdown) =>
        new CountDownState(
          countdown.title,
          new Date(countdown.date),
          countdown.displayTitle
        )
    );
  };
  const [countdowns, setCountdowns] = useState(loadCountdowns());

  const updateCountdowns = (updatedCountdowns) => {
    setCountdowns(updatedCountdowns);
    localStorage.setItem("countdowns", JSON.stringify(updatedCountdowns));
  };

  const addCountdown = (title, date) => {
    const newCountdown = new CountDownState(title, new Date(date), true);
    updateCountdowns([...countdowns, newCountdown]);
  };

  const removeCountdown = (i) => {
    const { [i]: c, ...rest } = countdowns;
    updateCountdowns(Object.values(rest));
  };

  const toggleCountdown = (i) => {
    countdowns[i].displayTitle = !countdowns[i].displayTitle;
    updateCountdowns([...countdowns]);
  };

  const [currDate, setCurrDate] = useState(null);

  const onDateCancel = () => {
    setCurrDate(null);
  };

  const onDateSubmit = (title) => {
    if (title == "") title = "Untitled";
    addCountdown(title, currDate);
    setCurrDate(null);
  };

  const contentRef = useRef();
  const countdownRenderer = () => (
    <div>
      <p>Enter a title for {formatDate(currDate)}</p>
      <input
        type="text"
        autofocus
        ref={contentRef}
        placeholder={"Untitled"}
        style={{ margin: "10px" }}
      ></input>
      <div>
        <button onClick={onDateCancel}>Cancel</button>
        <button onClick={() => onDateSubmit(contentRef.current.value)}>
          Submit
        </button>
      </div>
    </div>
  );

  return (
    <div className="countdown">
      <CountModal
        open={currDate != null}
        renderer={countdownRenderer}
        onCancel={onDateCancel}
      />
      <Countdown date={anniversaryDate} renderer={anniversaryRenderer} />
      {countdowns.map((countdown, i) => (
        <div>
          <Countdown
            date={countdown.date}
            renderer={rendererWrapper(countdown, i)}
          />
        </div>
      ))}
      <ModalVideo
        channel="custom"
        autoplay={true}
        url="./3.mp4"
        isOpen={open}
        onClose={() => setOpen(false)}
      />

      <DatePicker
        onChange={(date) => {
          if (
            countdowns.length < 4 &&
            !countdowns.find((item) => {
              return item.date.getTime() == date.getTime();
            })
          ) {
            setCurrDate(date);
          }
        }}
        customInput={
          <Button
            variant="secondary"
            style={{
              padding: "5px",
              paddingTop: "2px",
              paddingBottom: "2px",
              marginTop: "10px",
            }}
          >
            <AiOutlinePlusCircle style={{ marginRight: "5px" }} />
            Add Countdown
          </Button>
        }
      />
    </div>
  );
};
