import React, { useRef, useEffect, useCallback } from "react";
import styled from "styled-components";
import { MdClose } from "react-icons/md";
import { useSpring, animated } from "react-spring";

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
`;

const ModalWrapper = styled.div`
  width: 500px;
  min-height: 200px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: flex;
  overflow: auto;
  grid-template-columns: 1fr 1fr;
  position: relative;
  z-index: 10;
  border-radius: 10px;
  padding: 10px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  color: #141414;
  text-align: center;
  margin: auto;

  input {
    display: block;
    width: 100%;
    padding: 5px 8px;
    margin: 20px
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid grey;
    text-align: center;
    font-size: 15px;
  }

  button {
    padding: 10px 24px;
    background: #141414;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin: 7px;
    font-size: 15px;
  }
`;

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 100;
`;

export const CountModal = ({ open, renderer, onCancel }) => {
  const modalRef = useRef();
  const contentRef = useRef();

  const animation = useSpring({
    config: {
      duration: 200,
    },
    opacity: open ? 1 : 0,
  });

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onCancel();
    }
  };

  const keyPress = useCallback((e) => {
    if (e.key === "Enter" && open) {
      // update action
    } else if (e.key === "Escape" && open) {
      onCancel();
    }
  });

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  return (
    <>
      {open ? (
        <animated.div style={animation}>
          <Background ref={modalRef} onClick={closeModal}>
            <ModalWrapper showModal={open}>
              <ModalContent>{renderer()}</ModalContent>
              <CloseModalButton aria-label="Close modal" onClick={onCancel} />
            </ModalWrapper>
          </Background>
        </animated.div>
      ) : null}
    </>
  );
};
