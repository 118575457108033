import "./App.css";
import React from "react";
import { Container } from "react-layout-components";
import { Modal } from "./components/Modal";
import { CountModal } from "./components/CountModal";
import { CountDownHandler } from "./components/CountDownHandler";
import { FlippableImage } from "./components/FlippableImage";
import "bootstrap/dist/css/bootstrap.min.css";

const availablePlushies = ["octopus", "ghost"];

const imagePaths = {
  happyExt: "-happy.png",
  madExt: "-mad.png",
  red: "./uno.jpeg",
  green: "./uno2.jpeg",
};

const plushieStateName = "plushieStateV2";

class App extends React.Component {
  constructor(props) {
    super(props);

    try {
      var parsedState = JSON.parse(localStorage.getItem(plushieStateName));
      this.state = {
        plushie: parsedState.plushie,
        happy: parsedState.happy,
        happyMessage: parsedState.happyMessage,
        madMessage: parsedState.madMessage,
        messageType: parsedState.messageType,
        open: false,
        reverse: false,
        shop: false,
        selectedPlushie: null,
      };
    } catch (err) {
      this.state = {
        plushie: availablePlushies[0],
        happy: true,
        happyMessage: "Happy :)",
        madMessage: "MAD >:(",
        messageType: "",
        open: false,
        shop: false,
        selectedPlushie: null,
      };
    }
  }

  changeMessage = (messageType, message) => {
    if (message == null) {
      if (messageType == "happyMessage") message = "Happy :)";
      else message = "MAD >:(";
    }
    this.state[messageType] = message;
  };

  toggleImage = () => {
    this.setState((state) => (state.happy = !state.happy));
    fetch("/messages", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        message: !this.state.happy
          ? this.state["happyMessage"]
          : this.state["madMessage"],
        reverse: this.state.reverse,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      });
  };

  getImageName = () =>
    this.state.plushie +
    (this.state.happy ? imagePaths.happyExt : imagePaths.madExt);

  getReverseImage = () => (this.state.reverse ? "green" : "red");

  toggleModal = () => {
    this.setState((state) => (state.open = !state.open));
  };

  reverseNumber = () => {
    this.setState((state) => (state.reverse = !state.reverse));
  };

  toggleShop = () => {
    this.setState((state) => (state.shop = !state.shop));
  };

  updatePlushie = () => {
    this.setState((state) => (state.plushie = state.selectedPlushie));
  };

  selectPlushie = (plushie) => {
    this.setState((state) => (state.selectedPlushie = plushie));
  };

  shopRenderer = () => (
    <div className="shopBackground">
      <h3>Plushie Shop</h3>
      {availablePlushies.map((plushie) => (
        <FlippableImage
          src1={"./" + plushie + imagePaths.happyExt}
          src2={"./" + plushie + imagePaths.madExt}
          onSelect={() => this.selectPlushie(plushie)}
        />
      ))}
      <div>
        <button onClick={this.toggleShop}>Cancel</button>
        <button
          onClick={() => {
            this.updatePlushie();
            this.toggleShop();
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );

  render() {
    localStorage.setItem(plushieStateName, JSON.stringify(this.state));
    const image = this.getImageName();
    const reverse = this.getReverseImage();
    return (
      <>
        <Container>
          <Modal
            state={this.state}
            toggleModal={this.toggleModal}
            changeMessage={this.changeMessage}
          />
          <CountModal
            open={this.state.shop}
            renderer={this.shopRenderer}
            onCancel={this.toggleShop}
          />
          <div>
            <button
              className="menu"
              onClick={() => {
                this.state.messageType = "happyMessage";
                this.toggleModal();
              }}
            >
              Change Happy Message
            </button>
          </div>
          <div>
            <button
              className="menu"
              onClick={() => {
                this.state.messageType = "madMessage";
                this.toggleModal();
              }}
            >
              Change Mad Message
            </button>
          </div>
          <CountDownHandler />

          <img
            alt="reverse"
            className="reverse"
            src={imagePaths[reverse]}
            onClick={this.reverseNumber}
          />
          <img
            alt="shop"
            className="shop"
            src="./shop.png"
            onClick={this.toggleShop}
          />

          <img
            alt="plushie"
            className="plushie"
            src={image}
            onClick={this.toggleImage}
          />
        </Container>
      </>
    );
  }
}
export default App;
